import React from 'react'
import { graphql } from 'gatsby'
import { Flex } from 'rebass/styled-components'
import { Video } from '@roar/components'
import { mapper } from '../common/utils'
import { formatImage } from './ImageWrapper'

const VIDEO_MAP = {
  id: ['id'],
  image: (value) => formatImage(value, true),
  videoStyle: ({ style }) => {
    if (style && style.pbString) {
      return { imagePadding: style.pbString }
    }
    return {}
  },
  style: ['style'],
  schema: ['schema'],
  src: ['src'],
  headline: ['headline'],
  thumbnail: ['thumbnail', 'file', 'url'],
  previewUrl: ['previewUrl'],
  caption: ['caption'],
  colorScheme: ['style', 'colorScheme'],
  isVerticalVideo: ['isVerticalVideo'],
}

const VideoWrapper = (props) => {
  const {
    id,
    src,
    image,
    style,
    videoStyle,
    thumbnail = null,
    caption,
    previewUrl,
    colorScheme,
    headline,
    schema,
    isVerticalVideo,
  } = mapper(props, VIDEO_MAP)

  const schemaData = {
    '@context': 'https://schema.org/',
    '@type': 'VideoObject',
    ...schema,
  }

  return (
    <Flex id={id} flexDirection={'column'} {...style} sx={{ lineHeight: 0, width: ['100%'] }}>
      <Video
        src={src}
        colorScheme={colorScheme}
        image={image}
        thumbnail={thumbnail}
        previewUrl={previewUrl}
        headline={headline}
        caption={caption}
        isVerticalVideo={isVerticalVideo}
        {...videoStyle}
      />
      {schema && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }} />}
    </Flex>
  )
}

export default VideoWrapper

export const video = graphql`
  fragment Video on ContentfulVideo {
    id
    contentful_id
    name
    caption
    headline
    src: source
    previewUrl
    image: thumbnail {
      gatsbyImageData(layout: CONSTRAINED, width: 1200, quality: 90)
    }
    style {
      bg
      pb
      pl
      pr
      pt
      colorScheme
      justifyContent
      pbString
    }
    schema {
      name
      description
      thumbnailUrl
      uploadDate
      contentUrl
      duration
      embedUrl
    }
    isVerticalVideo
  }
`
